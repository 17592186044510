import styled, { css } from 'styled-components';
import { Typography } from '../../../../global/theme';

interface Props {
  isMilestonebox?: boolean;
  status?: number;
  ProgressPercentage?: number;
  isBorderColor?: string;
}

const Group = styled.div<Props>`
  display: flex;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 24px;
  scrollbar-width: none;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 449px) {
    gap: 16px;
  }
`;
const Podcastdiv = styled.div`
  max-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  @media (max-width: 449px) {
    display: none;
  }
`;

const Heading = styled.h1`
  ${Typography.heading_md}
  margin: 0;
  color: var(--text-primary);
  @media (max-width: 449px) {
    display: none;
  }
`;

const Profiles = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
  .button {
    display: none;
  }
  :hover {
    cursor: pointer;
    .button {
      display: block;
    }
  }
  .ant-tooltip-arrow {
    top: -6px !important;
  }
  .ant-tooltip .ant-tooltip-content {
    top: -7px;
  }
  .ant-tooltip .ant-tooltip-inner {
    background-color: var(--text-placeholder);
    border-radius: 4px;
    padding: 4px 8px;
    color: var(--text-primary);
    ${Typography.body_sm};
    min-height: unset;
  }
  .ant-tooltip {
    --antd-arrow-background-color: var(--text-placeholder);
  }

  @media (max-width: 449px) {
    gap: 4px 8px;
    .button {
      display: block;
    }
  }
`;
const Profileborder = styled.div`
  width: 34px;
  height: 34px;
  border: 0.5px solid var(--border-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Profilegroup = styled.div`
  display: flex;
  gap: 2px;
  height: 34px;
  border: 0.5px solid var(--border-primary);
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
`;
const Taskdiv = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  box-shadow: var(--shadow-card);
  background-color: var(--background-primary);
  padding: 15px 16px;
  display: flex;
  gap: 20px;
  @media (max-width: 449px) {
    flex-direction: column;
    padding: 16px;
  }
`;
const Task = styled.div`
  display: flex;
  gap: 16px;
  max-width: 209px;
  width: 100%;
  border-right: 0.5px solid var(--border-primary);
  :last-child {
    border-right: none;
  }
  @media (max-width: 449px) {
    border: none;
    align-items: center;
  }
`;
const Icon = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  background-color: var(--background-tertiary);
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  p {
    ${Typography.body_md_medium}
    margin: 0;
    color: var(--text-secondary);
  }
  h2 {
    ${Typography.heading_lg}
    margin: 0;
    color: var(--text-primary);
  }
  /* overide font size in h2  */
  @media (max-width: 449px) {
    h2 {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    ${Typography.heading_sm}
    margin: 0;
    color: var(--text-primary);
  }
`;

const Milestonebox = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  box-shadow: var(--shadow-card);
  background-color: var(--background-primary);
  padding: 15px 16px 5px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  p {
    ${Typography.heading_sm}
    margin: 0;
    color: var(--text-primary);
  }
  @media (max-width: 449px) {
    padding: 16px 16px 6px;
  }
`;
const Milestone = styled.div``;
const Div = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 9px;
  border-bottom: 0.5px solid var(--border-primary);
  :last-child {
    border-bottom: none;
    padding: 10px 0;
  }
  @media (max-width: 449px) {
    gap: 16px;
  }
`;
const Leftdiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  .header-icon {
    fill: var(--background-primary);
    stroke: var(--text-secondary);
    path {
      stroke: none;
    }
  }
  .milestone-icon {
    fill: var(--background-primary);
    path.border {
      stroke: var(--text-secondary);
    }
  }
  p {
    ${Typography.body_md_medium}
    margin: 0;
    color: var(--text-primary);
    span {
      padding-left: 6px;
    }
  }
  @media (max-width: 449px) {
    align-items: flex-start;
  }
`;
const Subdiv = styled.div`
  display: flex;
  gap: 6px;
  @media (max-width: 449px) {
    flex-wrap: wrap;
    max-width: 231px;
    width: 100%;
  }
`;
const Rightdiv = styled.div`
  ul {
    display: flex;
    flex-wrap: nowrap;
    list-style-type: none;
    padding: 0;
    margin: 0;
    justify-content: flex-end;
    height: 24px;
  }
  li {
    margin-left: -9px;
    width: 24px;
  }
`;
const AvatarImage = styled.div`
  display: flex;
  align-items: center;
  border-radius: 50%;

  > div > img {
    max-width: 100%;
  }
`;

const Graphbox = styled.div`
  border: 0.5px solid var(--border-primary);
  border-radius: 12px;
  box-shadow: var(--shadow-card);
  background-color: var(--background-primary);
  padding: 15px 16px;
  display: flex;
  gap: 12px;
  flex-direction: column;
`;
const Viewdropdown = styled.div`
  .menuStyle {
    display: flex;
    flex-direction: column;
    background: var(--background-primary);
    border: 0.5px solid var(--border-primary);
    border-radius: 8px;
    margin: 0px;
    padding: 6px 0;
    height: 100%;
    max-height: 205px;
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    .menuitem {
      color: var(--text-primary);
      ${Typography.body_md}
      margin: 0;
      padding: 6px 12px;
      :hover {
        background: var(--neutral-secondary);
        border-radius: 0;
      }
    }
  }
`;
const Status = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px 4px 12px;
  gap: 10px;
  background: var(--background-primary);
  border-radius: 8px;
  border: 0.5px solid var(--border-primary);
  width: 134px;
  cursor: pointer;
  p {
    ${Typography.body_md}
    margin: 0;
    color: var(--text-primary);
  }
  .fill-color {
    stroke: var(--text-primary);
  }
  .dropdown-icon {
    transform: rotate(180deg);
  }
`;
const Dropdowns = styled.div`
  position: relative;
`;
const FileEmptystate = styled.div<Props>`
  padding-bottom: 15px;
  ${(props) =>
    props.isMilestonebox &&
    css`
      padding-bottom: 25px;
    `}
`;

const Modalhead = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 16px 12px;
`;

const Headline = styled.h6`
  ${Typography.body_lg}
  color: var(--text-secondary);
  margin: 0;
`;

const Icon1 = styled.div`
  display: inline-flex;
  cursor: pointer;
  .closeicon {
    stroke: var(--text-primary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    border-radius: 4px;
  }
`;

const Modalblock = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Searchbar = styled.div`
  padding: 0 16px;
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h1 {
    ${Typography.body_lg}
    color: var(--text-secondary);
    margin: 0 0 16px;
  }
  .search {
    border: 0.5px solid var(--border-primary);
    border-radius: 6px;
    :focus {
      border: 0.5px solid var(--brand-primary);
    }
    :hover {
      border: 0.5px solid var(--brand-primary);
    }
  }
  .ant-input-group .ant-input {
    background-color: var(--background-primary);
  }
  .ant-input-search .ant-input:hover,
  .ant-input-search .ant-input:focus {
    border: none;
    box-shadow: none;
  }
  .ant-input-search .ant-input {
    border: none;
  }
  .ant-input::placeholder {
    color: var(--text-secondary);
    ${Typography.body_lg};
  }
  .ant-input {
    color: var(--text-primary);
    ${Typography.body_lg}
    padding: 8px 12px;
  }
  .ant-btn-default {
    background: var(--background-primary);
    box-shadow: none;
    border: none;
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary):hover {
    border: none;
  }
  .ant-input-search .ant-input-search-button {
    height: 36px;
  }
  .anticon svg {
    fill: var(--text-secondary);
  }
`;

const Members = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 176px;
  overflow: auto;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 20px;
  cursor: pointer;
  :hover {
    background-color: var(--neutral-secondary);
  }
  @media (max-width: 449px) {
    width: -webkit-fill-available;
    justify-content: space-between;
  }
`;

const Name = styled.div`
  width: 332px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  p {
    ${Typography.body_md_medium}
    text-align: right;
    margin: 0;
    color: var(--text-primary);
  }
  @media (max-width: 449px) {
    width: unset;
  }
`;

const Check = styled.div`
  display: flex;
  gap: 10px;

  .checkbox-round {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--border-primary);
    appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 7px;
  }
  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    opacity: 0.5;
  }
  input[type='checkbox']:checked {
    background-color: var(--brand-primary);
    opacity: 1;
    border: 1px solid var(--brand-primary);
  }
  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 3px;
    height: 8px;
    border: solid var(--text-white);
    border-width: 0 2px 2px 0;
    margin: -0.5px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%) scale(0.7);
    z-index: 2;
  }
`;

const Loadingimage = styled.div`
  position: relative;
  left: 50%;
  width: max-content;
  margin: 187px 0;
`;
const ResourcesSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  @media (max-width: 449px) {
    flex-direction: column;
  }
`;
const ResourceTitle = styled.div`
  ${Typography.body_md_medium};
  color: var(--text-primary);
  padding: 3px 0;
`;
const ResourcesDrodpownHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 5px;
  border-radius: 6px;
  p {
    margin: 0;
    ${Typography.body_md_medium};
    color: var(--text-secondary);
  }
  :hover {
    background-color: var(--neutral-secondary);
    p {
      color: var(--text-primary);
    }
    svg {
      path {
        fill: var(--text-primary);
      }
    }
  }
  &.hide-text {
    padding: 5px;
    p {
      display: none;
    }
  }
`;

const AddedLinkBox = styled.div`
  background-color: var(--background-primary);
  border: 0.5px solid var(--border-primary);
  border-radius: 6px;
  padding: 2px 4px 2px 8px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  &:hover {
    background-color: var(--neutral-secondary);
  }
`;

const LinkIcon = styled.div`
  display: flex;
`;

const Text = styled.p`
  margin: 0;
  ${Typography.body_md_medium};
  color: var(--text-primary);
`;
const MoreIcon = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  .fill-color {
    fill: var(--text-secondary);
    stroke: var(--text-secondary);
  }
  &:hover {
    background-color: var(--background-muted);
    .fill-color {
      fill: var(--text-primary);
      stroke: var(--text-primary);
    }
  }
`;

const ResourcesFileSection = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
`;

const WrapperDiv = styled.div`
  display: flex;
  margin-top: -24px;
  height: calc(100dvh - 51px);
  @media (max-width: 449px) {
    height: calc(100vh - 100px);
  }
`;
const FileIconDiv = styled.div`
  position: relative;
  display: flex;

  .file-text {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 35%;
    left: 25%;
    width: 50%;
    font-weight: 700;
    color: var(--text-secondary);
  }
  .upload-file-text {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 28%;
    left: 25%;
    width: 50%;
    font-weight: 700;
    color: var(--text-primary);
  }
`;
const Uploadingcard = styled.div`
  width: 100%;
  max-width: 216px;
  border: 0.5px solid var(--border-primary);
  background-color: var(--background-primary);
  box-shadow: var(--shadow-modal);
  border-radius: 8px;
  padding: 11px;
  margin-bottom: 20px;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 1;
  @media (max-width: 449px) {
    transform: translateX(-50%);
  }
`;

const Uploadingcontent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const Filetext = styled.div`
  width: 142px;
  display: flex;
  flex-direction: column;
`;
const Filename = styled.div`
  ${Typography.body_md_medium}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-primary);
`;
const Filesize = styled.div`
  ${Typography.body_xs}
  color: var(--text-secondary);
`;
const ProgressDiv = styled.div`
  display: flex;
  padding: 0;
  margin-top: 6px;
  .ant-progress-line .ant-progress-outer .ant-progress-inner div.ant-progress-bg {
    height: 3px !important;
  }
  .ant-progress .ant-progress-inner {
    background-color: var(--border-primary);
    margin-top: -21px;
  }
  .ant-progress .ant-progress-text {
    position: absolute;
    bottom: 15px;
    right: 0;
    ${Typography.body_md}
    color: var(--text-primary);
  }
  .ant-progress.ant-progress-show-info .ant-progress-outer {
    margin-inline-end: 0;
    padding-inline-end: 0;
    height: 3px;
  }
  .prograss {
    .ant-progress-outer {
    }
  }
`;
export {
  Group,
  Podcastdiv,
  Top,
  Heading,
  Profiles,
  Profileborder,
  Profilegroup,
  Taskdiv,
  Task,
  Icon,
  Content,
  Header,
  Milestonebox,
  Milestone,
  Div,
  Leftdiv,
  Rightdiv,
  Subdiv,
  AvatarImage,
  Graphbox,
  Viewdropdown,
  Status,
  Dropdowns,
  FileEmptystate,
  Modalhead,
  Headline,
  Icon1,
  Modalblock,
  Searchbar,
  ModalContent,
  Members,
  Profile,
  Name,
  Check,
  Loadingimage,
  ResourcesSection,
  ResourceTitle,
  ResourcesDrodpownHeading,
  AddedLinkBox,
  LinkIcon,
  Text,
  MoreIcon,
  ResourcesFileSection,
  WrapperDiv,
  Uploadingcontent,
  Uploadingcard,
  Filename,
  Filesize,
  Filetext,
  ProgressDiv,
  FileIconDiv
};
