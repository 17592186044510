import styled from 'styled-components';
import { calculateColor } from '../../helpers/common';

interface ProgressProps {
  size?: number;
  ProgressPercentage?: number;
  status?: number;
}

const ProgressContainer = styled.div<ProgressProps>`
  width: ${({ size }) => size || 12}px;
  height: ${({ size }) => size || 12}px;
  border: 0.5px solid ${({ status }) => calculateColor(status || 0)};
  border-radius: 50%;
  padding: 2px;
  @media (max-width: 449px) {
    width: 14px;
    height: 14px;
  }
`;

const ProgressFill = styled.div<ProgressProps>`
  width: ${({ size }) => size || 12}px;
  height: ${({ size }) => size || 12}px;
  border-radius: 50%;
  background: conic-gradient(
    ${({ status }) => calculateColor(status || 0)} ${({ ProgressPercentage }) => ProgressPercentage}%,
    0,
    transparent 100%
  );
  @media (max-width: 449px) {
    width: 14px;
    height: 14px;
  }
`;

export { ProgressFill, ProgressContainer };
