import React from 'react';
import { calculateColor } from '../../helpers/common';
import { ProgressContainer, ProgressFill } from './styles';

interface CircularProgressProps {
  progressPercentage: number;
  status?: number;
  className?: string;
  size?: number;
}

const CircularProgress: React.FC<CircularProgressProps> = ({ progressPercentage, status, className, size = 12 }) => {
  const color = calculateColor(status || 0);

  return (
    <ProgressContainer size={size} status={status} className={className}>
      <ProgressFill size={size} color={color} status={status} ProgressPercentage={progressPercentage} />
    </ProgressContainer>
  );
};

export default CircularProgress;
