import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import SVGIcon from '../../../../assets/images/svg/SVGIcon';
import MyCustomContextMenu from '../../../../component/customContextMenu';
import EmptyState from '../../../../component/emptyState';
import { COMMENT_TYPE, LABEL_COLOUR_HASH, MENU_OPTIONS } from '../../../../global/constants';
import { calculateDueTime } from '../../../../helpers/common';
import {
  File,
  MilestoneListInterface,
  ProjectDetailInterface,
  StatusListInterface
} from '../../../../interfaces/ProjectInterface';
import {
  Milestoneitem,
  Iconhide,
  Header,
  Leftheader,
  Title,
  Rightheader,
  Assigngroup1,
  AvatarImage,
  Plus,
  Icon2,
  Second,
  SVGDiv,
  Milestoneempty,
  Emptybox,
  Data,
  ShowTask,
  Showhide,
  Content,
  IconDueDate,
  Assigngroup,
  DeadBlock,
  Deadline,
  Doticon,
  Dropblock,
  Droplabel,
  Form,
  Iconhidden,
  Inputvalue,
  Label,
  Multiplelabel,
  Taskcontent,
  Taskitem,
  Text,
  TopEmpty,
  BottomEmpty,
  Emptytitle,
  Images,
  ButtonIcon,
  TooltipContent
} from './styles';
import Button from '../../../../component/button';
import Avatar from '../../../../component/avatar/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { useMobileDevice } from '../../../../global/useMobile';
import { setMilestoneInput, updateArchiveMilestoneList, updateMilestoneList } from '../../../../actions/projectActions';
import {
  setCreateTaskInput,
  setMyTaskDetailsData,
  updateCreateTaskInput,
  updateMyTaskData
} from '../../../../actions/taskActions';
import {
  createNewComment,
  getMyTaskDetailsData,
  getTaskGroupList,
  onChangeComment,
  updateTaskDetails
} from '../../../../services/taskServices';
import { RootReducerInterface } from '../../../../interfaces/RootReducerInterface';
import taskdarkempty from '../../../../assets/images/emptystates/darkmytaskempty.svg';
import tasklightempty from '../../../../assets/images/emptystates/mytask-empty.svg';
import AssignBox from '../../../../component/dropdowns/assignPopup';
import Prioritydropdown from '../../../../component/dropdowns/priorityDropdown';
import TaskStatusDropdown from '../../../../component/dropdowns/taskStatusDropdown';
import { ProjectTemplateDetailsInterface } from '../../../../interfaces/SettingsInterface';
import { DataUpdatedCommentsInterface, TaskDetailsInterface } from '../../../../interfaces/TaskInterface';
import { TASK_ANALYTICS, VIEW_ANALYTICS } from '../../../../global/analyticsConstants';
import { trackAnalyticActivity } from '../../../../services/analyticsService';
import { useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';
import UserPreferenceSingleton from '../../../../helpers/userPreferenceSingleton';
import { updateMilestone } from '../../../../services/projectServices';
import { Dropdown, DropdownItem } from '../../../../component/Dropdown';
import { captureException } from '../../../../services/logService';
import { menuEditItems, menuRestoreItems } from '../../../../global/row';
import Tooltip from '../../../../component/Tooltip';
import CircularProgress from '../../../../component/CircularProgress';

interface Props {
  item: MilestoneListInterface;
  isMilestoneItem: boolean;
  loadData: () => void;
  milestoneIndex: number;
  completedTaskList: TaskDetailsInterface[];
  newOldestDate: any;
  newestDueDate: any;
  uncompletedTaskList: TaskDetailsInterface[];
  closeArchiveModal: () => void;
  setButtonLoading: (value: boolean) => void;
  setDeleteModelopen: (value: boolean) => void;
  setTaskDeleteModelopen: (value: boolean) => void;
  setSelectedItem: (value: TaskDetailsInterface) => void;
  setIsOpenMilestoneModal: (value: boolean) => void;
  setArchiveModelopen: (value: boolean) => void;
  setOpen: (value: boolean) => void;
  selectedId: string;
  setSelectedId: (value: string) => void;
  provided?: any;
}
const MilestoneItem: React.FC<Props> = (props) => {
  const {
    item,
    isMilestoneItem,
    loadData,
    milestoneIndex,
    newestDueDate,
    newOldestDate,
    completedTaskList,
    uncompletedTaskList,
    closeArchiveModal,
    setButtonLoading,
    setDeleteModelopen,
    setTaskDeleteModelopen,
    setSelectedItem,
    setIsOpenMilestoneModal,
    setArchiveModelopen,
    setOpen,
    selectedId,
    setSelectedId,
    provided
  } = props;

  //States
  const [showCompletedTask, setShowCompletedTask] = useState(false);
  const [currentActiveTask, setCurrentActiveTask] = useState<string>('');
  const [isAssignDropdownOpen, setIsAssignDropdownOpen] = useState(false);
  const [currentAssignBox, setCurrentAssignBox] = useState<string>('');

  // use selector state variables
  const stateSelector = useSelector((state: RootReducerInterface) => state);
  const {
    settings: settingsSelector,
    project: projectSelector,
    workspace: workspaceSelector,
    auth: authSelector,
    task: tasksSelector
  } = stateSelector || {};
  const { themeMode } = settingsSelector;
  const { workspace } = workspaceSelector;
  const { id: workspace_id } = workspace;
  const { userDetails } = authSelector;
  const { milestoneList, archiveMilestoneList, currentProject } = projectSelector;
  const { taskGroups } = tasksSelector;

  // Other variables
  const dispatch = useDispatch();
  const mobile = useMobileDevice();
  const history = useHistory();

  const EmptyStateImage = () => (themeMode?.theme === 'dark' ? taskdarkempty : tasklightempty);

  //handle edit milestone
  const onClickEdit = useCallback(
    (item: MilestoneListInterface) => {
      setIsOpenMilestoneModal(true);
      dispatch(setMilestoneInput(item));
    },
    [dispatch, setIsOpenMilestoneModal]
  );

  //open archive milestone model
  const openArchiveMilestoneModel = useCallback(
    (itemId: string) => {
      setSelectedId(itemId);
      setArchiveModelopen(true);
    },
    [setArchiveModelopen, setSelectedId]
  );

  //handle retrieve milestone
  const onClickRetrieve = useCallback(
    async (itemId: string) => {
      try {
        setButtonLoading(true);
        const payload = {
          milestoneId: itemId,
          isArchived: false
        };
        const result = await dispatch(updateMilestone(payload));
        if (result) {
          closeArchiveModal();
          loadData();
        }
      } catch (error) {
        captureException(error);
      } finally {
        setButtonLoading(false);
      }
    },
    [closeArchiveModal, dispatch, loadData, setButtonLoading]
  );

  //handle milestone dropdown menu click
  const handleMenuClick = useCallback(
    (key: number | string, item: MilestoneListInterface) => {
      key = Number(key);
      if (key === MENU_OPTIONS.EDIT) {
        onClickEdit(item);
      } else if (key === MENU_OPTIONS.ARCHIVE) {
        openArchiveMilestoneModel(item?.id);
      }
    },
    [onClickEdit, openArchiveMilestoneModel]
  );

  //delete milestone model
  const openDeleteMilestoneModel = useCallback(
    (itemId: string) => {
      setSelectedId(itemId);
      setDeleteModelopen(true);
    },
    [setDeleteModelopen, setSelectedId]
  );

  //create milestone task model
  const openCreateTaskModel = useCallback(
    async (milestone: MilestoneListInterface) => {
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      const result = await dispatch(getTaskGroupList(currentProject?.id, true));
      dispatch(updateCreateTaskInput({ propsName: 'milestoneId', value: milestone?.id }));
      if (result?.length > 0) {
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: result[0] }));
      }
      dispatch(updateCreateTaskInput({ propsName: 'priority', value: currentProject?.priority?.default || 0 }));
      setOpen(true);
    },
    [currentProject, dispatch, setOpen]
  );

  //handle archive dropdown menu option click
  const handleMenuOptionClick = useCallback(
    (key: number | string, item: MilestoneListInterface) => {
      key = Number(key);
      if (key === MENU_OPTIONS.RESTORE) {
        onClickRetrieve(item?.id);
      } else if (key === MENU_OPTIONS.DELETE) {
        openDeleteMilestoneModel(item?.id);
      }
    },
    [onClickRetrieve, openDeleteMilestoneModel]
  );

  //update task status
  const onChangeStatus = useCallback(
    async (
      status: StatusListInterface,
      previousStatus: StatusListInterface,
      task: TaskDetailsInterface,
      index: number
    ) => {
      const payloadTask = { statusId: status['_id'], projectId: task?.projectId };
      let taskData = task ? JSON.parse(JSON.stringify(task)) : {};
      const updatedComment = onChangeComment(task);
      taskData.statusId = status['_id'];
      taskData.companyId = workspace_id;
      taskData.updatedComments = updatedComment?.updatedComments;
      let subData = archiveMilestoneList[index] ? JSON.parse(JSON.stringify(archiveMilestoneList[index])) : {};
      subData.tasks = subData.tasks?.map((obj: TaskDetailsInterface) => {
        return obj.id === taskData.id ? taskData : obj;
      });
      dispatch(updateMyTaskData({ value: subData, index: index }));
      const result = await dispatch(updateTaskDetails(task?.id, payloadTask));
      if (result) {
        await dispatch(
          createNewComment(task?.id, {
            Type: COMMENT_TYPE.STATUS_ID_CHANGE,
            Group: status?.Name,
            PreviousGroup: previousStatus?.Name,
            Priority: status?.Type
          })
        );
        trackAnalyticActivity(TASK_ANALYTICS.STATUS_CHANGED);
      }
      if (loadData) loadData();
    },
    [archiveMilestoneList, dispatch, loadData, workspace_id]
  );

  //render task content
  const renderTaskContent = useCallback(
    (
      milestoneIndex: number,
      task: TaskDetailsInterface | undefined,
      statusDetails: StatusListInterface | undefined,
      currentProject: ProjectDetailInterface | ProjectTemplateDetailsInterface | undefined,
      loadData: (() => void) | undefined,
      onClickMilestoneTask: (task: TaskDetailsInterface) => void,
      onClickTaskCheckbox: (
        e: React.ChangeEvent<HTMLInputElement>,
        task: TaskDetailsInterface,
        status: boolean,
        milestoneId: string,
        isArchive?: boolean
      ) => void,
      onClickUsers: (taskId: string) => void,
      isAssignDropdownOpen: boolean,
      setIsAssignDropdownOpen: Function,
      currentAssignBox: string | undefined,
      setCurrentAssignBox: ((id: string) => void) | undefined,
      LABEL_COLOUR_HASH: { [x: string]: string },
      dueResult: {
        dueParam: string;
        dueTime: string;
        overDue: boolean;
      },
      userDetails: { dateFormat: string | undefined },
      milestoneId: string,
      isArchive: boolean
    ) => {
      return (
        <Taskcontent
          onClick={() => onClickMilestoneTask(task || ({} as TaskDetailsInterface))}
          isActive={currentActiveTask === task?.id}>
          <Iconhidden onClick={(e) => e.stopPropagation()}>
            <SVGIcon
              className='svgicon'
              name='three-dots-vertical-icon'
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
            />
          </Iconhidden>
          <Taskitem>
            <Form>
              <div style={{ display: 'flex', gap: 2 }}>
                {statusDetails ? (
                  <TaskStatusDropdown
                    defaultStatus={statusDetails}
                    statusList={currentProject?.statusData || []}
                    currentStatusId={task?.statusId}
                    isTaskCheckBox={true}
                    onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                      onChangeStatus(status, previousStatus, task || ({} as TaskDetailsInterface), milestoneIndex)
                    }
                    isResponsiveStatusIcon={true}
                    isHoverIcon={true}
                  />
                ) : (
                  <ButtonIcon>
                    <Inputvalue
                      onClick={(e) => e.stopPropagation()}
                      type='checkbox'
                      className='checkbox-round'
                      onChange={(e) =>
                        onClickTaskCheckbox(
                          e,
                          task || ({} as TaskDetailsInterface),
                          !task?.status,
                          milestoneId,
                          isArchive
                        )
                      }
                      checked={task?.status}
                    />
                  </ButtonIcon>
                )}
                {currentProject?.priority?.enabled && (
                  <Prioritydropdown
                    taskData={task}
                    icon={true}
                    currentProject={currentProject}
                    loadData={loadData}
                    isTaskItem={true}
                    isBorder={true}
                    isTaskCheckBox={true}
                    isHoverIcon={true}
                  />
                )}
              </div>
              <Label>{task?.name}</Label>
            </Form>
            <Assigngroup1>
              <Assigngroup onClick={(e) => e.stopPropagation()}>
                <ul onClick={() => onClickUsers(task?.id || '')}>
                  {task?.users
                    ?.filter((item) => !isEmpty(item?.id))
                    ?.map((userProfile) => (
                      <li key={userProfile?.id}>
                        <AvatarImage>
                          <Avatar
                            size={20}
                            imgSrc={userProfile?.profile_photo}
                            name={userProfile?.name}
                            isMilestoneBorder={true}
                          />
                        </AvatarImage>
                      </li>
                    ))}
                  <AssignBox
                    setIsDropdownOpen={setIsAssignDropdownOpen}
                    isDropdownOpen={isAssignDropdownOpen && currentAssignBox === task?.id}
                    projectUsers={currentProject?.users || []}
                    currentTask={task}
                    loadData={loadData}
                    setCurrentAssignBox={setCurrentAssignBox}
                    currentAssignBox={currentAssignBox}
                    isMilestone={true}
                    isMultiAssignee={currentProject?.multiAssignee}
                  />
                </ul>
              </Assigngroup>
            </Assigngroup1>
          </Taskitem>
          <Deadline hasGap={isEmpty(task?.dueDate)}>
            {!isEmpty(task?.labels) && (
              <Multiplelabel>
                {task?.labels?.map((label, index) => (
                  <Dropblock className={index === 0 ? 'left-border' : ''} key={label?.id}>
                    <Doticon color={`${LABEL_COLOUR_HASH[label?.color]}`} />
                    <Droplabel>{label?.name}</Droplabel>
                  </Dropblock>
                ))}
              </Multiplelabel>
            )}
            {!isEmpty(task?.dueDate) && (
              <DeadBlock>
                <Tooltip
                  title={
                    <>
                      <div>Due {dueResult?.dueParam ?? ''}</div>
                      <div>Due on {moment(task?.dueDate).format(userDetails?.dateFormat)}</div>
                    </>
                  }
                  placement='bottom'
                  maxWidth={'max-content'}>
                  <TooltipContent>
                    <Text isOverdue={dueResult?.overDue && !task?.status}>{task?.dueDate && dueResult?.dueTime}</Text>
                    <IconDueDate>
                      {dueResult?.overDue && !task?.status ? (
                        <SVGIcon
                          name='tasklist-calendar-duedate-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          className='calendar-due-fill-color'
                        />
                      ) : (
                        <SVGIcon
                          name='tasklist-calendar-icon'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          className='calendar-fill-color'
                        />
                      )}
                    </IconDueDate>
                  </TooltipContent>
                </Tooltip>
              </DeadBlock>
            )}
          </Deadline>
        </Taskcontent>
      );
    },
    [currentActiveTask, onChangeStatus]
  );

  //handle task click
  const onClickMilestoneTask = useCallback(
    (task: TaskDetailsInterface) => {
      const eventProperties = {
        source: 'milestone task list'
      };
      trackAnalyticActivity(VIEW_ANALYTICS.MILESTONE_TASK, eventProperties);
      dispatch(setMyTaskDetailsData(task));
      history.push(`/projects/details/${task?.projectId}/tasks?task=${task?.id}`);
    },
    [dispatch, history]
  );

  //handle clone task
  const onClickClone = useCallback(
    async (task: TaskDetailsInterface) => {
      let taskData;
      taskData = await dispatch(getMyTaskDetailsData(task?.id));
      const taskFiles = taskData?.Files?.map((item: File) => {
        const { __typename, ...other } = item;
        return other;
      });
      const cloneTaskdetails = {
        Files: taskFiles,
        description: taskData?.description || '',
        name: taskData?.name,
        groupId: taskData?.groupId,
        projectId: taskData?.projectId,
        priority: taskData?.priority,
        users: taskData?.users,
        labels: taskData?.labels,
        estimate: taskData?.estimate,
        milestoneId: taskData?.milestoneId,
        statusId: taskData?.statusId,
        parentTaskId: !isEmpty(taskData?.parentTaskId) ? taskData?.parentTaskId : null
      };
      dispatch(setCreateTaskInput(cloneTaskdetails));
      if (!isEmpty(task?.groupId)) {
        const groupId = taskGroups?.find((group) => group.id === task?.groupId);
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: groupId }));
      }
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      await dispatch(getTaskGroupList(currentProject?.id, true));
      dispatch(
        updateCreateTaskInput({
          propsName: 'priority',
          value:
            taskData?.priority !== undefined
              ? taskData.priority
              : currentProject?.priority?.default !== undefined
              ? currentProject.priority.default
              : 0
        })
      );
      setOpen(true);
    },
    [currentProject, dispatch, setOpen, taskGroups]
  );

  //handle edit task
  const onClickTaskEdit = useCallback(
    async (task: TaskDetailsInterface) => {
      let taskData;
      taskData = await dispatch(getMyTaskDetailsData(task?.id));
      dispatch(setCreateTaskInput(taskData));
      if (!isEmpty(task?.groupId)) {
        const groupId = taskGroups?.find((group) => group.id === task?.groupId);
        dispatch(updateCreateTaskInput({ propsName: 'groupId', value: groupId }));
      }
      dispatch(updateCreateTaskInput({ propsName: 'projectId', value: currentProject }));
      await dispatch(getTaskGroupList(currentProject?.id, true));
      dispatch(
        updateCreateTaskInput({
          propsName: 'priority',
          value:
            taskData?.priority !== undefined
              ? taskData.priority
              : currentProject?.priority?.default !== undefined
              ? currentProject.priority.default
              : 0
        })
      );
      setOpen(true);
    },
    [currentProject, dispatch, setOpen, taskGroups]
  );

  //delete task model
  const Opendeletemodel = (task?: TaskDetailsInterface) => {
    if (task) setSelectedItem(task);
    setTaskDeleteModelopen(true);
  };

  //toggle completed task
  const togglecomplatedtask = useCallback(
    (value: boolean, itemId: string) => {
      if (!value) {
        setSelectedId('');
      }
      setSelectedId(itemId);
      setShowCompletedTask(value);
    },
    [setSelectedId]
  );

  //handle assign dropdown click
  const onClickUsers = useCallback(
    (taskId: string) => {
      if (mobile) {
        setIsAssignDropdownOpen(true);
        setCurrentAssignBox(taskId);
      }
    },
    [mobile]
  );

  //handle task checkbox click
  const onClickTaskCheckbox = useCallback(
    async (
      e: React.SyntheticEvent,
      item: TaskDetailsInterface,
      value: boolean,
      milestoneId: string,
      isArchive = false
    ) => {
      e.stopPropagation();
      let taskData = JSON.parse(JSON.stringify(item));
      const userDetails = UserPreferenceSingleton.getInstance().getCurrentUser();
      const newComment: DataUpdatedCommentsInterface = {
        id: nanoid(),
        createdOn: new Date().toISOString(),
        updatedBy: userDetails?.id,
        updatedTime: new Date().toISOString(),
        type: COMMENT_TYPE.STATUS_CHANGE,
        status: value,
        user: { ...userDetails, name: userDetails?.given_name },
        isArchived: false
      };
      const allComments = item?.updatedComments;
      const comments = [...(allComments || []), newComment];
      taskData.status = value;
      taskData.companyId = workspace?.id;
      taskData.updatedComments = comments;
      if (isArchive) {
        const milestoneCloneList: MilestoneListInterface[] = JSON.parse(JSON.stringify(archiveMilestoneList));
        const milestone = milestoneCloneList?.find((milestone) => milestone?.id === milestoneId);
        const tasksList = milestone?.tasks?.map((obj) => {
          return obj?.id === taskData.id ? taskData : obj;
        });
        if (milestone && tasksList) {
          milestone.tasks = tasksList;
          dispatch(updateArchiveMilestoneList(milestone));
        }
      } else {
        const milestoneCloneList: MilestoneListInterface[] = JSON.parse(JSON.stringify(milestoneList));
        const milestone = milestoneCloneList?.find((milestone) => milestone?.id === milestoneId);
        const tasksList = milestone?.tasks?.map((obj) => {
          return obj?.id === taskData.id ? taskData : obj;
        });

        if (milestone && tasksList) {
          milestone.tasks = tasksList;
          dispatch(updateMilestoneList(milestone));
        }
      }
      const payloadTask = { status: value, projectId: item?.projectId };
      const result = await dispatch(updateTaskDetails(item?.id, payloadTask));

      const commentResult = await dispatch(
        createNewComment(item?.id, {
          Type: COMMENT_TYPE.STATUS_CHANGE,
          Status: value
        })
      );

      if (result && commentResult) {
        if (value) trackAnalyticActivity(TASK_ANALYTICS.MARKED_DONE);
        else trackAnalyticActivity(TASK_ANALYTICS.REOPENED);
      }
    },
    [workspace?.id, dispatch, archiveMilestoneList, milestoneList]
  );

  const menuEditItemsMapped = useMemo(
    () =>
      menuEditItems.map((menuItem) => (
        <DropdownItem
          label={menuItem.label}
          key={menuItem.key}
          onClick={() => handleMenuClick(menuItem.key, item)}
          tone={menuItem.label === 'Archive' ? 'critical' : 'primary'}
        />
      )),
    [handleMenuClick, item]
  );

  const menuRestoreItemsMapped = useMemo(
    () =>
      menuRestoreItems.map((menuItem) => (
        <DropdownItem
          label={menuItem.label}
          key={menuItem.key}
          onClick={() => handleMenuOptionClick(menuItem.key, item)}
          tone={menuItem.label === 'Delete' ? 'critical' : 'primary'}
        />
      )),
    [handleMenuOptionClick, item]
  );

  return (
    <>
      <Milestoneitem
        ref={isMilestoneItem ? provided?.innerRef : undefined}
        {...(isMilestoneItem ? provided?.draggableProps : {})}>
        <Iconhide onClick={(e) => e.stopPropagation()} {...(isMilestoneItem ? provided?.dragHandleProps : {})}>
          <SVGIcon
            className='dotted-icon'
            name='three-dots-vertical-icon'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
          />
        </Iconhide>
        <Header>
          <Leftheader>
            <CircularProgress progressPercentage={Number(item?.progress)} status={item?.status} />
            <Title>{item?.milestoneName}</Title>
          </Leftheader>
          <Rightheader>
            <Assigngroup1>
              <ul>
                {item?.users
                  ?.filter((item) => !isEmpty(item?.id))
                  ?.map((userProfile, indexUser) => {
                    if (indexUser > 2 && mobile) return null;
                    return (
                      <li key={item?.id}>
                        <AvatarImage>
                          <Avatar
                            size={20}
                            imgSrc={userProfile?.profile_photo || ''}
                            name={userProfile?.name}
                            isMilestoneBorder={true}
                          />
                        </AvatarImage>
                      </li>
                    );
                  })}
                {item?.users?.length > 3 && mobile && (
                  <li className='plus-icon'>
                    <Plus>+{item?.users?.length - 3}</Plus>
                  </li>
                )}
              </ul>
            </Assigngroup1>
            <Dropdown
              content={
                <Icon2>
                  <SVGIcon
                    className='dot-icon'
                    name='milestone-three-dot-verticle-icon'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                  />
                </Icon2>
              }
              trigger='click'>
              {isMilestoneItem ? menuEditItemsMapped : menuRestoreItemsMapped}
            </Dropdown>
          </Rightheader>
        </Header>
        {item?.tasks?.length > 0 && (
          <Second hasContent={item?.tasks?.length > 0}>
            <Content>
              <p>
                {moment(newOldestDate).format('DD MMMM')} {!isEmpty(newestDueDate) && '->'}{' '}
                {!isEmpty(newestDueDate) && moment(newestDueDate).format('DD MMMM')}
              </p>
              <SVGDiv>
                <SVGIcon name='gray-icon' width='4' height='4' viewBox='0 0 4 4' fill='var(--text-secondary)' />
              </SVGDiv>
              <p>
                {completedTaskList?.length} Tasks completed out of {item?.tasks?.length}
              </p>
            </Content>
            {isMilestoneItem ? (
              <Button
                title='Create Task'
                secondary={true}
                smallbutton={true}
                hasNotBoxshadow
                onClick={() => openCreateTaskModel(item)}
              />
            ) : (
              <></>
            )}
          </Second>
        )}
        {item?.tasks?.length === 0 && (
          <Milestoneempty>
            <Emptybox>
              {isMilestoneItem ? (
                <EmptyState
                  image={EmptyStateImage()}
                  header='No task created yet'
                  onButtonClick={() => openCreateTaskModel(item)}
                  name='Create Task'
                  isMilestoneTaskEmpty={true}
                  isgap={12}
                  hasNotPlusIcon={true}
                />
              ) : (
                <>
                  <TopEmpty>
                    <Images>
                      {themeMode?.theme === 'dark' ? (
                        <img src={taskdarkempty} alt='darktaskempty' width={160} height={88} className='empty-image' />
                      ) : (
                        <img
                          src={tasklightempty}
                          alt='emptytaskcreen'
                          width={160}
                          height={88}
                          className='empty-image'
                        />
                      )}
                    </Images>
                    <Emptytitle>No task created yet</Emptytitle>
                  </TopEmpty>
                  <BottomEmpty></BottomEmpty>
                </>
              )}
            </Emptybox>
          </Milestoneempty>
        )}
        {item?.tasks?.length > 0 && (
          <>
            {uncompletedTaskList?.length > 0 && (
              <Data isShowAllCompletedTask={completedTaskList.length === item.tasks.length}>
                {uncompletedTaskList?.map((task) => {
                  let dueResult: { overDue: boolean; dueTime: string; dueParam?: string } | undefined;
                  if (task?.dueDate) {
                    dueResult = calculateDueTime(task.dueDate);
                  }
                  let statusDetails: StatusListInterface | undefined;
                  if (currentProject?.statusEnable && currentProject?.statusData?.length > 0) {
                    if (!task?.statusId) statusDetails = currentProject?.defaultStatus;
                    else statusDetails = currentProject?.statusData?.find((x) => x['_id'] === task?.statusId);
                  }
                  return (
                    <>
                      <MyCustomContextMenu
                        targetId={task?.id}
                        taskData={task}
                        loadData={loadData}
                        currentProject={currentProject}
                        currentActiveTask={currentActiveTask}
                        setCurrentActiveTask={(taskId: string) => setCurrentActiveTask(taskId)}
                        onClickView={() => onClickMilestoneTask(task)}
                        onClickClone={() => onClickClone(task)}
                        onClickEdit={() => onClickTaskEdit(task)}
                        statusList={currentProject?.statusData || []}
                        onClickDelete={() => Opendeletemodel(task)}
                        onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                          onChangeStatus(status, previousStatus, task, milestoneIndex)
                        }
                        isMilestonePage={true}>
                        {renderTaskContent(
                          milestoneIndex,
                          task,
                          statusDetails,
                          currentProject,
                          loadData,
                          onClickMilestoneTask,
                          onClickTaskCheckbox,
                          onClickUsers,
                          isAssignDropdownOpen,
                          setIsAssignDropdownOpen,
                          currentAssignBox,
                          setCurrentAssignBox,
                          LABEL_COLOUR_HASH,
                          dueResult as { dueParam: string; dueTime: string; overDue: boolean },
                          userDetails,
                          item?.id,
                          !isMilestoneItem
                        )}
                      </MyCustomContextMenu>
                    </>
                  );
                })}
              </Data>
            )}
            {completedTaskList?.length > 0 && (
              <>
                <ShowTask>
                  {selectedId === item?.id && showCompletedTask ? (
                    <Showhide onClick={() => togglecomplatedtask(false, '')}>Hide Completed Tasks</Showhide>
                  ) : (
                    <Showhide onClick={() => togglecomplatedtask(true, item?.id)}>Show Completed Tasks</Showhide>
                  )}
                  {selectedId === item?.id && showCompletedTask && (
                    <Data isShowCompletedTask={true}>
                      {completedTaskList?.map((task) => {
                        let dueResult: { overDue: boolean; dueTime: string; dueParam?: string } | undefined;
                        if (task?.dueDate) {
                          dueResult = calculateDueTime(task.dueDate);
                        }
                        let statusDetails: StatusListInterface | undefined;
                        if (currentProject?.statusEnable && currentProject?.statusData?.length > 0) {
                          if (!task?.statusId) statusDetails = currentProject?.defaultStatus;
                          else statusDetails = currentProject?.statusData?.find((x) => x['_id'] === task?.statusId);
                        }
                        return (
                          <MyCustomContextMenu
                            key={task?.id}
                            targetId={task?.id}
                            taskData={task}
                            loadData={loadData}
                            currentProject={currentProject}
                            currentActiveTask={currentActiveTask}
                            setCurrentActiveTask={(taskId: string) => setCurrentActiveTask(taskId)}
                            onClickView={() => onClickMilestoneTask(task)}
                            onClickClone={() => onClickClone(task)}
                            onClickEdit={() => onClickTaskEdit(task)}
                            statusList={currentProject?.statusData || []}
                            onClickDelete={() => Opendeletemodel(task)}
                            onUpdateStatus={(status: StatusListInterface, previousStatus: StatusListInterface) =>
                              onChangeStatus(status, previousStatus, task, milestoneIndex)
                            }>
                            {renderTaskContent(
                              milestoneIndex,
                              task,
                              statusDetails,
                              currentProject,
                              loadData,
                              onClickMilestoneTask,
                              onClickTaskCheckbox,
                              onClickUsers,
                              isAssignDropdownOpen,
                              setIsAssignDropdownOpen,
                              currentAssignBox,
                              setCurrentAssignBox,
                              LABEL_COLOUR_HASH,
                              dueResult as { dueParam: string; dueTime: string; overDue: boolean },
                              userDetails,
                              item?.id,
                              !isMilestoneItem
                            )}
                          </MyCustomContextMenu>
                        );
                      })}
                    </Data>
                  )}
                </ShowTask>
              </>
            )}
          </>
        )}
      </Milestoneitem>
    </>
  );
};

export default MilestoneItem;
